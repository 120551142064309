import React from 'react'

function Casestudy() {

    
    return (
        <div>
            <h1>Project Name</h1>
            <h2>purpose</h2>
            <h2>techstack</h2>
            <h2>challenges</h2>
        </div>
    )
}

export default Casestudy