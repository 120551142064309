import './LoaderPage.scss';


function LoaderPage() {
    return (
        <div className='loader'>
            <div className='loader__circle'>
            </div>
        </div>
    )
}

export default LoaderPage